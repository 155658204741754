<template>
    <div>
        <a data-type="switchButtonRowLink" title="Switch Project" v-if="row['switch_control']===true" :href="switchProjectURL">
            <v-icon left>mdi-camera-switch</v-icon>
        </a>
    </div>
</template>
<script>
  export default {
    props: ["row", "value", "valueKey", "currentTable"],
    computed: {
      switchProjectURL() {
        return '?f=administration&f2=projectAdministration&function=switchProject&n_id='+this.value+'&n_id_key=' + this.valueKey;
      }
    }
  };
</script>